import PostApi from 'piramis-base-components/src/plugins/AdminTemplate/includes/logic/Api/PostApi'
import BaseApi from '@/includes/services/BaseApi'

export default class CSVExportService {
	static ExportBotUsers(type: string, board_key: string, properties: Array<number>): Promise<any> {
		return BaseApi.sendRequest('tg', 'exportbotusers', { board_key, properties })
			.then((data) => {
				const blob = new Blob([ data ], { type: 'application/pgp-encrypted' });
				const link = document.createElement('a');
				link.href = window.URL.createObjectURL(blob);
				link.download = 'users.csv';

				document.body.appendChild(link);

				link.click();

				document.body.removeChild(link);
			})
	}

	static ExportCashRegistries(type: string, board_key: string): void {
		window.open(`${ PostApi.getRequestUrl(type, 'ExportCashRegistries') }?board_key=${ board_key }`, '_black')
	}

	static ExportProducts(type: string, board_key: string): void {
		window.open(`${ PostApi.getRequestUrl(type, 'ExportProducts') }?board_key=${ board_key }`, '_black')
	}

	static ExportSales(type: string, board_key: string): void {
		window.open(`${ PostApi.getRequestUrl(type, 'ExportSales') }?board_key=${ board_key }`, '_black')
	}
}
