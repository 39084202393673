var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-modal',{attrs:{"ok-text":_vm.$t('t_me_send_message_button'),"after-close":_vm.reset,"destroy-on-close":""},on:{"ok":_vm.handleOk,"cancel":_vm.reset},model:{value:(_vm.isModalOpen),callback:function ($$v) {_vm.isModalOpen=$$v},expression:"isModalOpen"}},[(_vm.isMounted)?_c('message-editor-with-media-input',{attrs:{"setup":{
      'func': _vm.modelSetter,
      'args': {
        'model': this,
        'key': 'message',
        'prefix': 'bot_send_message_',
        'hasMedia': true,
        'targetToUpload': {
          'target': 'target',
          'target_id': _vm.$store.state.boardsState.activeBoard.board
        },
        'availableButtonsTypes': _vm.newPmButtons,
        'buttonsHelpView': _vm.EditorButtonsHelpView,
      }
    }}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }