var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('page-title',{attrs:{"translation-params":[_vm.$store.state.boardsState.activeBoard.title, _vm.$store.state.boardsState.activeBoard.board.split('.')[0]]},scopedSlots:_vm._u([{key:"custom-button",fn:function(){return [_c('a-button',{attrs:{"icon":"download"},on:{"click":function($event){_vm.isExportBotUsersModalOpen = true}}},[_vm._v(" CSV ")])]},proxy:true}])}),_c('a-modal',{staticClass:"modal-overflow-visible",on:{"ok":_vm.exportToCsv},model:{value:(_vm.isExportBotUsersModalOpen),callback:function ($$v) {_vm.isExportBotUsersModalOpen=$$v},expression:"isExportBotUsersModalOpen"}},[_c('a-alert',{staticClass:"mt-5",attrs:{"message":_vm.$t('export_bot_users_alert'),"show-icon":""}}),_c('select-input',{attrs:{"setup":{
        'func': _vm.modelSetter,
        'args': {
          'model': this,
          'key': 'exportProperties',
          'options': _vm.propertiesOptions,
          'clearable': true,
          'multiple': true
        }
      }}})],1),(_vm.config)?_c('bot-users',{attrs:{"config":_vm.config}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }